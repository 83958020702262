import * as React from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from '../../Stores/MainStore';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import confirmation from "../Confirmation/confirmation.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Choice = observer(() => {

    const { ConfigStore } = useStores();

    const navigate = useNavigate();

    const handleClose = () => {
        ConfigStore.setIsChoiceShow(false);
    };

    const toUser = () => {
        handleClose();
        navigate("/signup");
    };

    const toOwner = () => {
        handleClose();
        navigate("/signup/owner");
    };

    return (
        <Dialog
            open={ConfigStore.isChoiceShow}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                {ConfigStore.lang === "de" ? "Sind Sie Besucher?" : "Are you visitor?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{ padding: "10px" }}>
                    <Button onClick={toOwner} style={{ color: "#005cb9" }}>{ConfigStore.lang === "de" ? "Inhaber/in" : "Owner"}</Button>
                    <Button onClick={toUser} style={{ color: "#005cb9" }}>{ConfigStore.lang === "de" ? "Gäst" : "Visitor"}</Button> 
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
});

export default Choice;