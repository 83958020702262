import React, { useState, useRef } from 'react';
import { QrScanner } from 'react-qr-scanner';

const QRCodeScanner = () => {
  const [scanResult, setScanResult] = useState('');
  const videoRef = useRef(null);

  const handleScan = (result) => {
    if (result) {
      setScanResult(result);
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  return (
    <div>
      <QrScanner
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%', height: 'auto' }}
        videoConstraints={{
          facingMode: 'environment', // Задайте 'user' для использования передней камеры
        }}
        ref={videoRef}
      />
      {scanResult && (
        <div>
          <p>Отсканированный результат:</p>
          <p>{scanResult}</p>
        </div>
      )}
    </div>
  );
};

export default QRCodeScanner;