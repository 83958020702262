import * as React from 'react';
import "./vfooter.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faQrcode, faHome } from "@fortawesome/free-solid-svg-icons";
import { observer } from 'mobx-react';
import { useStores } from '../../Stores/MainStore';

const VFooter = observer(() => {

    const { ConfigStore } = useStores();

    const handleClick = (buttonId) => {
        ConfigStore.setActiveButton(buttonId);
    };

    return (
        <footer className="vfooter">
            <div
                className="footer__icon icon_me"
                style={{ background: ConfigStore.activeButton === 1 ? 'rgba(255, 255, 255, 0.15)' : 'transparent' }}
                onClick={() => handleClick(1)}
            >
                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
                <p>me</p>
            </div>
            <div
                className="footer__icon icon_qr"
                style={{ background: ConfigStore.activeButton === 2 ? 'rgba(255, 255, 255, 0.15)' : 'transparent' }}
                onClick={() => handleClick(2)}
            >
                <FontAwesomeIcon icon={faQrcode}></FontAwesomeIcon>
                <p>QR</p>
            </div>
            <div
                className="footer__icon icon_home"
                style={{ background: ConfigStore.activeButton === 3 ? 'rgba(255, 255, 255, 0.15)' : 'transparent' }}
                onClick={() => handleClick(3)}
            >
                <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
                <p>home</p>
            </div>
        </footer>
    );
});

export default VFooter;