import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../Stores/MainStore";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const Offers = observer((props) => {

    const { RequestStore, ConfigStore } = useStores();

    const { myData } = props;

    const [posts, setPosts] = useState("");

    useEffect(() => {
        if(myData) {
            new Promise((resolve, reject) => {
                resolve();
            })
            .then(() => {
                return RequestStore.doGet(ConfigStore._url +  "/posts/")
            })
            .then((res) => {
                setPosts(res);
            })
        } else {
            return;
        }
    }, [myData, RequestStore, ConfigStore, posts]);

    return(
        <div className="about__offers" style={{ display: ConfigStore.activeButton === 3 ? "flex" : "none" }} >
            {posts ? 
                posts.slice().reverse().map(post => 
                    <div className="offers__one" key={post._id}>
                        <p>{post.gift}</p>
                        <p>{myData.bonus}/{post.required_bonuses} kroken</p>
                        <Box sx={{ width: 250 }}>
                            <Slider
                                value={myData.bonus}
                                min={0}
                                max={post.required_bonuses * 1}
                                valueLabelDisplay="auto"
                                aria-labelledby="non-linear-slider"
                            />
                        </Box>
                    </div>
                )
                :
                <div className="loader">Loading...</div>
            }
        </div>
    );
});
export default Offers;