import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../Stores/MainStore";
import { DataGrid } from '@mui/x-data-grid';
import "./owner.scss"

const Offers = observer(() => {

    const { ConfigStore } = useStores();

    const columns = [
        {
            field: 'offer',
            headerName: 'Offer',
            type: 'string',
            width: 180
        },
        {
          field: 'bonus',
          headerName: 'Bonus',
          type: 'number',
          width: 90
        },
        {
            field: 'date',
            headerName: 'Date',
            type: 'string',
            width: 120
        },
    ];
    
    const rows = ConfigStore.posts.map((post) => ({
        id: post._id,
        offer: post.gift,
        bonus: post.required_bonuses,
        date: post.date
    }))

    return(
        <div className="offers__table" style={{ display: ConfigStore.activeownButton === 2 ? "block" : "none" }}>
            <div style={{ height: '100%', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>
        </div>
    );
});

export default Offers;