import React, { useEffect } from "react";
import Offers from "./Offers";
import VFooter from "./VFooter";
import QRCodeGenerator from "./QRCodeGen";
import { observer } from "mobx-react";
import { useStores } from "../../Stores/MainStore";
import Feedback from "../../Partial/Feedback/Feedback";
import Me from "./Me";
import Snack from "../../Partial/Snack";
import "./visitor.scss";

const Visitor = observer(() => {

    const { RequestStore, ConfigStore } = useStores();

    useEffect(() => {
        new Promise((resolve, reject) => {
            resolve();
        })
        .then(() => {
            return RequestStore.doGet(ConfigStore._url + "/me/" + localStorage.getItem("myAppId"))
        })
        .then((res) => {
            ConfigStore.setMyData(res);
        })
    }, [RequestStore, ConfigStore]);

    return(
        <>
            <div className="page__visitor">
                <div className="visitor__about">
                    <Me />
                    <QRCodeGenerator />
                    <Offers myData={ConfigStore.myData} />
                </div>
                <VFooter />
                <div className="page__bg"></div>
                <Feedback />
            </div>
            <Snack />
        </>
    );
});

export default Visitor;