import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react";
import { TextField, Button } from "@mui/material";
import { language } from "../lang";
import { useStores } from "../Stores/MainStore";
import "../Users/Owner/owner.scss";

const SendOffer = observer(() => {

    const { RequestStore, ConfigStore } = useStores();

    const [condition, setCondition] = useState("");
    const [requiredBonuses, setRequiredBonuses] = useState("");
    const [gift, setGift] = useState("");
    const [lng, setLng] = useState(ConfigStore.lang);

    const {
        register,
        handleSubmit,
        resetField,
        formState: { errors },
    } = useForm({
        mode: "onChange",
    });
    const onSubmit = (data) => {
        new Promise((resolve, reject) => {
            resolve();
        })
        .then(() => {
            return RequestStore.doPost(ConfigStore._url + "/offer", {
                business_name       : ConfigStore.myData.business_name,
                condition           : data.condition,
                required_bonuses    : data.requiredBonuses,
                gift                : data.gift
            })
        })
        .then((res) => {
            if(res.acknowledged) {
                resetField("condition");
                resetField("requiredBonuses");
                resetField("gift");
                ConfigStore.setSeverity("success");
                ConfigStore.setTextAlert(lng === "de" ? "Erfolg!" : "Success!");
                RequestStore.doGet(ConfigStore._url + "/posts/" + ConfigStore.businessName)
                .then((res) => {
                    ConfigStore.setPosts(res);
                });
                ConfigStore.setIsSnackShow(true);
            } else {
                ConfigStore.setSeverity("error");
                ConfigStore.setTextAlert(lng === "de" ? "Angebot bereits vorhanden!" : "Offer already existing!");
                ConfigStore.setIsSnackShow(true);
            }
        })
    };

    useEffect(() => {
        setLng(ConfigStore.lang);
    }, [ConfigStore.lang]);

    return(
        <div className="about__newOffer" style={{ display: ConfigStore.activeownButton === 2 ? "flex" : "none" }} >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="newOffer__form">
                    <span className="form__title">{lng === "de" ? language.sendNewOffer.de : language.sendNewOffer.en}:</span>
                    <TextField
                        label={lng === "de" ? language.cond.de : language.cond.en}
                        variant="standard" 
                        type="text"
                        fullWidth
                        {...register("condition", {
                            required: `${lng === "de" ? language.required.de : language.required.en}`,
                            minLength: {
                                value: 2,
                                message: `${lng === "de" ? language.min2symb.de : language.min2symb.en}`
                            },
                            value: condition,
                            onChange: (e) => {
                                setCondition(e.target.value)
                            }
                        })}
                    />
                    <p className="errorMessage">{errors.condition && errors.condition.message}</p>
                    <TextField
                        label={lng === "de" ? language.required_bon.de : language.required_bon.en}
                        variant="standard"
                        type="number"
                        fullWidth
                        {...register("requiredBonuses", {
                            required: `${lng === "de" ? language.required.de : language.required.en}`,
                            value: requiredBonuses,
                            onChange: (e) => {
                                setRequiredBonuses(e.target.value)
                            }
                        })}
                    />
                    <p className="errorMessage">{errors.requiredBonuses && errors.requiredBonuses.message}</p>
                    <TextField
                        label={lng === "de" ? language.gift.de : language.gift.en}
                        variant="standard"
                        type="text"
                        fullWidth
                        {...register("gift", {
                            required: `${lng === "de" ? language.required.de : language.required.en}`,
                            value: gift,
                            onChange: (e) => {
                                setGift(e.target.value)
                            }
                        })}
                    />
                    <p className="errorMessage">{errors.gift && errors.gift.message}</p>
                </div>
                <Button type="submit" variant="outlined">
                    {lng === "de" ? language.send.de : language.send.en}
                </Button>
            </form>
        </div>
    );
});

export default SendOffer;