import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "../../Stores/MainStore";
import ErrorModal from "../../Partial/ErrorModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Feedback from "../../Partial/Feedback/Feedback";
import { language } from "../../lang";
import "./signUp.scss"
import { faChevronRight, faLock, faUser, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SignUp = observer(() => {

  const { RequestStore, ConfigStore } = useStores();

  const navigate = useNavigate();

    const [username, setUsername]               = useState("");
    const [password, setPassword]               = useState("");
    const [lng, setLng]                         = useState(ConfigStore.lang);
    const [isVisible, setIsVisible]             = useState("password");

    let usF = `${lng === "de" ? "Benutzername bereits vorhanden" : "Username already existing"}`

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "onChange",
      });
      const onSubmit = (data) => {
        new Promise((resolve, reject) => {
          resolve();
        })
        .then(() => {
          return RequestStore.doPost(ConfigStore._url + "/signup", {
            username        : (data.username).replace(/ /g,"_"),
            password        : data.password,
            name            : "name",
            surname         : "surname",
            birthday        : new Date().toLocaleDateString(),
            who             : "visitor",
            bonus           : 2
          })
        })
        .then((res) => {
          if (res.acknowledged) {
            localStorage.setItem('myAppId', res.insertedId);
            navigate("/user");
          } else {
            res.status === 403 ? ConfigStore.setErr(usF) : ConfigStore.setErr(res.statusText);
            ConfigStore.setIsShow(true);
          }
        })
      };

      useEffect(() => {
        setLng(ConfigStore.lang);
      }, [ConfigStore.lang]);

      const showPassword = () => {
        if(isVisible === "password") {
          setIsVisible("text");
        } else {
          setIsVisible("password");
        }
      };

    return(
        <div className="signup">
          <ErrorModal />
          <Feedback />
          <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container">
                    <div className="screen">
                        <div className="screen__content">
                            <div className="loginSignUp">
                            <div className="login__field">
                                    <FontAwesomeIcon icon={faUser} className="login__icon"></FontAwesomeIcon>
                                    <input
                                        type="text"
                                        className="login__input"
                                        placeholder={lng === "de" ? language.logname.de : language.logname.en}
                                        {...register("username", {
                                            required: `${lng === "de" ? language.required.de : language.required.en}`,
                                            minLength: {
                                              value: 8,
                                              message: `${lng === "de" ? language.min8symb.de : language.min8symb.en}`
                                            },
                                            pattern: {
                                              value:
                                              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@\S+(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                                              message: `${lng === "de" ? language.email.de : language.email.en}`,
                                              },
                                            value: username,
                                            onChange: (e) => {
                                              setUsername(e.target.value);
                                            }
                                        })}
                                    />
                                    <p className="errorMessage">{errors.username && errors.username.message}</p>
                                </div>
                                
                                <div className="login__field">
                                  <FontAwesomeIcon icon={faLock} className="login__icon"></FontAwesomeIcon>
                                    <input
                                        type={isVisible}
                                        className="login__input"
                                        placeholder={lng === "de" ? language.password.de : language.password.en}
                                        {...register("password", {
                                            required: `${lng === "de" ? language.required.de : language.required.en}`,
                                            minLength: {
                                                value: 8,
                                                message: `${lng === "de" ? language.min8symb.de : language.min8symb.en}`
                                            },
                                            pattern: {
                                              value: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                                              message: `${lng === "de" ? language.password_valid.de : language.password_valid.en}`
                                            },
                                            value: password,
                                            onChange: (e) => {
                                              setPassword(e.target.value);
                                            }
                                        })}
                                    />
                                    <FontAwesomeIcon
                                      icon={isVisible === "password" ? faEyeSlash : faEye}
                                      className="login__icon icon__eye"
                                      onClick={showPassword}
                                    ></FontAwesomeIcon>
                                    <p className="errorMessage">{errors.password && errors.password.message}</p>
                                </div>
                                <button type="submit" className="button login__submit">
                                    <span className="button__text">
                                      {lng === "de" ? language.signup.de : language.signup.en}
                                    </span>
                                    <FontAwesomeIcon icon={faChevronRight} className="button__icon"></FontAwesomeIcon>
                                </button>			
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="signin__bg"></div>
        </div>
    );
});

export default SignUp;