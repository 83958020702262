import React, { useState, useEffect } from "react";
import "./owner.scss";
import { observer } from "mobx-react";
import { useStores } from "../../Stores/MainStore";
import { language } from "../../lang";
import { Button, TextField } from '@mui/material';
import OUpdateMeModal from "../../Partial/OUpdateMeModal";

const OMe = observer(() => {

    const { ConfigStore } = useStores();

    const [lng, setLng] = useState(ConfigStore.lang);

    useEffect(() => {
        setLng(ConfigStore.lang);
    }, [ConfigStore.lang]);

    const handleOpenUpdMe = () => {
        ConfigStore.setIsOUpdateMeShow(true);
        
    };

    return(
        <div className="about__info" style={{ display: ConfigStore.activeownButton === 1 ? "flex" : "none" }} >
            <div className="info__me">
                {ConfigStore.ownerData.username ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label={(lng === "de" ? language.username.de : language.username.en) + ":"}
                        value={(ConfigStore.ownerData.username).replace(/_/g," ")}
                        fullWidth
                    />
                ) : (
                    null
                )}
                {ConfigStore.ownerData.business_name ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label={(lng === "de" ? language.businessName.de : language.businessName.en) + ":"}
                        value={(ConfigStore.ownerData.business_name).replace(/_/g," ")}
                        fullWidth
                    />
                ) : (
                    null
                )}
                {ConfigStore.ownerData.address ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label="Address:"
                        value={(ConfigStore.ownerData.address).replace(/_/g," ")}
                        fullWidth
                    />
                ) : (
                    null
                )}
                {ConfigStore.ownerData.taxId ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label="Tax ID:"
                        value={(ConfigStore.ownerData.taxId).replace(/_/g," ")}
                        fullWidth
                    />
                ) : (
                    null
                )}
                {ConfigStore.ownerData.name ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label={(lng === "de" ? language.name.de : language.name.en) + ":"}
                        value={(ConfigStore.ownerData.name).replace(/_/g," ")}
                        fullWidth
                    />
                ) : (
                    null
                )}
                {ConfigStore.ownerData.surname ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label={(lng === "de" ? language.surname.de : language.surname.en) + ":"}
                        value={(ConfigStore.ownerData.surname).replace(/_/g," ")}
                        fullWidth
                    />
                ) : (
                    null
                )}
                {ConfigStore.ownerData.phone ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label={(lng === "de" ? language.phone.de : language.phone.en) + ":"}
                        value={ConfigStore.ownerData.phone}
                    />
                ) : (
                    null
                )}
                {ConfigStore.ownerData.bonus ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label={"Bonus:"}
                        value={ConfigStore.ownerData.bonus}
                    />
                ) : (
                    null
                )}
                <Button
                    onClick={handleOpenUpdMe}
                    variant="contained"
                >
                    {lng === "de" ? language.updateMe.de : language.updateMe.en}
                </Button>
                <OUpdateMeModal />
            </div>
        </div>
    );
});

export default OMe;