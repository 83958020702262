import * as React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../Stores/MainStore";
import QRCodeScanner from "./QRCodeScan";

const Service = observer(() => {

    return(
        <>
            <QRCodeScanner />
        </>
    );

});

export default Service;