import * as React from 'react';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from '../Stores/MainStore';
// eslint-disable-next-line no-unused-vars
import confirmation from "./Confirmation/confirmation.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorModal = observer(() => {

    const { ConfigStore } = useStores();

    function handleClose() {
        ConfigStore.setIsShow(false);
    }

    return(
        <Dialog
            open={ConfigStore.isShow}
            TransitionComponent={Transition}
            onClose={handleClose}
            style={{
                marginTop: "60px"
            }}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
                {ConfigStore.lang === "de" ? "Fehler" : "Error"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{ padding: "20px" }}>
                    {ConfigStore.err}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="secondary" onClick={handleClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
});

export default ErrorModal;