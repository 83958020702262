import React, { useState, useEffect } from "react";
import AllOffers from "./AllOffers";
import OfferHistory from "./OfferHistory";
import Offers from "./Offers";
import { observer } from "mobx-react";
import { useStores } from "../../Stores/MainStore";
import Snack from "../../Partial/Snack";
import Feedback from "../../Partial/Feedback/Feedback";
import SendOffer from "../../Partial/SendOffer";
import { language } from "../../lang";
import OFooter from "./OFooter";
import OMe from "./OMe";
import "./owner.scss";

const Owner = observer(() => {

    const { RequestStore, ConfigStore } = useStores();

    const [lng, setLng] = useState(ConfigStore.lang);

    useEffect(() => {
        new Promise((resolve, reject) => {
            resolve();
        })
        .then(() => {
            return RequestStore.doGet(ConfigStore._url + "/me-owner/" + localStorage.getItem("myAppId"))
        })
        .then((res) => {
            ConfigStore.setOwnerData(res);
            ConfigStore.setBusinessName(res.business_name);
        })
    }, [RequestStore, ConfigStore])

    useEffect(() => {
        setLng(ConfigStore.lang);
    }, [ConfigStore.lang]);
      
    return(
        <>
            <div className="page__owner">
                <div className="owner__about">
                    <OMe />
                    {/* <SendOffer /> */}
                    <Offers />
                    <AllOffers />
                    {/* <OfferHistory myData={myData} /> */}
                </div>
                <div className="page__bg"></div>
            </div>
            <Snack />
            <Feedback />
            <OFooter />
        </>
    );
});

export default Owner;