import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "../../../Stores/MainStore";
import ErrorModal from "../../../Partial/ErrorModal";
import { typeListen, typeListde } from "../../../TypeList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Feedback from "../../../Partial/Feedback/Feedback";
import { language } from "../../../lang";
import "./suowner.scss"
import {
  faBriefcase,
  faChevronDown,
  faChevronRight,
  faLock,
  faUser,
  faEye,
  faEyeSlash,
  faAddressBook,
  faPhone,
  faIdCard
} from "@fortawesome/free-solid-svg-icons";

const SUOwner = observer(() => {

  const { RequestStore, ConfigStore } = useStores();

  const navigate = useNavigate();

    const [username, setUsername]               = useState("");
    const [business_type, setBusiness_type]     = useState("restaurant");
    const [business_name, setBusiness_name]     = useState("");
    const [address, setAddress]                 = useState("");
    const [phone, setPhone]                     = useState("");
    const [taxId, setTaxId]                     = useState("");
    const [password, setPassword]               = useState("");
    const [lng, setLng]                         = useState(ConfigStore.lang);
    const [typeList, setTypeList]               = useState(typeListde);
    const [isVisible, setIsVisible]             = useState("password");

    let usF = `${lng === "de" ? "Benutzername bereits vorhanden" : "Username already existing"}`

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "onChange",
      });
      const onSubmit = (data) => {
        new Promise((resolve, reject) => {
          resolve();
        })
        .then(() => {
          return RequestStore.doPost(ConfigStore._url + "/signup-owner", {
            username        : (data.username).replace(/ /g,"_"),
            name            : "name",
            surname         : "surname",
            password        : data.password,
            business_name   : (data.business_name).replace(/ /g,"_"),
            address         : (data.address).replace(/ /g,"_"),
            phone           : data.phone,
            taxId           : "DE"+data.taxId,
            type_business   : data.business_type,
            who             : "owner"
          })
        })
        .then((res) => {
          if (res.acknowledged) {
            localStorage.setItem('myAppId', res.insertedId);
            navigate("/owner");
          } else {
            res.status === 403 ? ConfigStore.setErr(usF) : ConfigStore.setErr(res.statusText);
            ConfigStore.setIsShow(true);
          }
        })
      };

      useEffect(() => {
        setLng(ConfigStore.lang);
        setTypeList(ConfigStore.lang === "de" ? typeListde : typeListen);
      }, [ConfigStore.lang]);

      const showPassword = () => {
        if(isVisible === "password") {
          setIsVisible("text");
        } else {
          setIsVisible("password");
        }
      };

    return(
        <div className="signup">
          <ErrorModal />
          <Feedback />
          <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container">
                    <div className="screen screenOwner">
                        <div className="screen__content">
                            <div className="loginSignUp ownerGrid">
                                <div className="login__field username">
                                    <FontAwesomeIcon icon={faUser} className="login__icon"></FontAwesomeIcon>
                                    <input
                                        type="text"
                                        className="login__input"
                                        placeholder={lng === "de" ? language.logname.de : language.logname.en}
                                        {...register("username", {
                                            required: `${lng === "de" ? language.required.de : language.required.en}`,
                                            minLength: {
                                                value: 8,
                                                message: `${lng === "de" ? language.min8symb.de : language.min8symb.en}`
                                            },
                                            pattern: {
                                            value:
                                            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@\S+(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                                            message: `${lng === "de" ? language.email.de : language.email.en}`,
                                            },
                                            value: username,
                                            onChange: (e) => {
                                                setUsername(e.target.value);
                                            }
                                        })}
                                    />
                                    <p className="errorMessage">{errors.username && errors.username.message}</p>
                                </div>
                                
                                <div className="login__field business_type" style={{paddingTop: "0px"}}>
                                    <FontAwesomeIcon icon={faChevronDown} className="login__icon"></FontAwesomeIcon>
                                    <label
                                        htmlFor="businessTypeInput"
                                        style={{
                                            color: "#ffffff99"
                                        }}
                                    >
                                        {lng === "de" ? language.business_type.de : language.business_type.en}
                                    </label>
                                  <select
                                    id="businessTypeInput"
                                    className="login__input"
                                    {...register("business_type", {
                                    required: `${lng === "de" ? language.required.de : language.required.en}`,
                                    value: business_type,
                                    onChange: (e) => {
                                      setBusiness_type(e.target.value);
                                    }
                                  })}>
                                    {typeList.map(item => <option key={item.value} value={item.value}>{item.name}</option>)}
                                  </select>
                                </div>
                                <div className="login__field business_name">
                                    <FontAwesomeIcon icon={faBriefcase} className="login__icon"></FontAwesomeIcon>
                                    <input
                                        type="text"
                                        className="login__input"
                                        placeholder={lng === "de" ? language.businessName.de : language.businessName.en}
                                        {...register("business_name", {
                                            required: `${lng === "de" ? language.required.de : language.required.en}`,
                                            minLength: {
                                                value: 2,
                                                message: `${lng === "de" ? language.min2symb.de : language.min2symb.en}`
                                            },
                                            value: business_name,
                                            pattern: {
                                                value: /[^\s\-][\w \-]+[^\s\-]$/g,
                                                message: `${lng === "de" ? language.business_name_valid.de : language.business_name_valid.en}`
                                            },
                                            onChange: (e) => {
                                              setBusiness_name(e.target.value);
                                            }
                                        })}
                                    />
                                    <p className="errorMessage">{errors.business_name && errors.business_name.message}</p>
                                </div>
                                <div className="login__field address">
                                    <FontAwesomeIcon icon={faAddressBook} className="login__icon"></FontAwesomeIcon>
                                    <input
                                        type="text"
                                        className="login__input"
                                        placeholder={lng === "de" ? "Adresse" : "Address"}
                                        {...register("address", {
                                            required: `${lng === "de" ? language.required.de : language.required.en}`,
                                            pattern: {
                                                value: /[A-Za-z0-9'\.\-\s\,]$/g,
                                                message: `${lng === "de" ? "Ungültige Adresse" : "Invalid address"}`
                                            },
                                            value: address,
                                            onChange: (e) => {
                                                setAddress(e.target.value);
                                            }
                                        })}
                                    />
                                    <p className="errorMessage">{errors.address && errors.address.message}</p>
                                </div>
                                <div className="login__field phone">
                                    <FontAwesomeIcon icon={faPhone} className="login__icon"></FontAwesomeIcon>
                                    <input
                                        type="text"
                                        className="login__input"
                                        placeholder={lng === "de" ? "Telefonnummer" : "Phone number"}
                                        {...register("phone", {
                                            required: `${lng === "de" ? language.required.de : language.required.en}`,
                                            pattern: {
                                                value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/g,
                                                message: `${lng === "de" ? language.phone_num_valid.de : language.phone_num_valid.en}`
                                            },
                                            value: phone,
                                            onChange: (e) => {
                                                setPhone(e.target.value);
                                            }
                                        })}
                                    />
                                    <p className="errorMessage">{errors.phone && errors.phone.message}</p>
                                </div>
                                <div className="login__field taxId">
                                    <FontAwesomeIcon icon={faIdCard} className="login__icon"></FontAwesomeIcon>
                                    <input
                                        type="text"
                                        className="login__input"
                                        placeholder={lng === "de" ? "Umsatzsteuer-Identifikationsnummer" : "VAT number"}
                                        {...register("taxId", {
                                            required: `${lng === "de" ? language.required.de : language.required.en}`,
                                            pattern: {
                                                value: /^\d{9,9}$/g,
                                                message: `${lng === "de" ? "Ungültig, nur 9 Ziffern" : "Invalid, only 9 digits"}`
                                            },
                                            value: taxId,
                                            onChange: (e) => {
                                                setTaxId(e.target.value);
                                            }
                                        })}
                                    />
                                    <p className="errorMessage">{errors.taxId && errors.taxId.message}</p>
                                </div>
                                <div className="login__field password">
                                  <FontAwesomeIcon icon={faLock} className="login__icon"></FontAwesomeIcon>
                                    <input
                                        type={isVisible}
                                        className="login__input"
                                        placeholder={lng === "de" ? language.password.de : language.password.en}
                                        {...register("password", {
                                            required: `${lng === "de" ? language.required.de : language.required.en}`,
                                            minLength: {
                                                value: 8,
                                                message: `${lng === "de" ? language.min8symb.de : language.min8symb.en}`
                                            },
                                            pattern: {
                                                value: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                                                message: `${lng === "de" ? language.password_valid.de : language.password_valid.en}`
                                            },
                                            value: password,
                                            onChange: (e) => {
                                              setPassword(e.target.value);
                                            }
                                        })}
                                    />
                                    <FontAwesomeIcon
                                      icon={isVisible === "password" ? faEyeSlash : faEye}
                                      className="login__icon icon__eye"
                                      onClick={showPassword}
                                    ></FontAwesomeIcon>
                                    <p className="errorMessage">{errors.password && errors.password.message}</p>
                                </div>
                                <button type="submit" className="button login__submit">
                                    <span className="button__text">
                                      {lng === "de" ? language.signup.de : language.signup.en}
                                    </span>
                                    <FontAwesomeIcon icon={faChevronRight} className="button__icon"></FontAwesomeIcon>
                                </button>			
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="signin__bg"></div>
        </div>
    );
});

export default SUOwner;