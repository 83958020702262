import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { observer } from 'mobx-react';
import { useStores } from '../../Stores/MainStore';

const QRCodeGenerator = observer(() => {

  const { ConfigStore } = useStores();

  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    setCurrentURL(window.location.href + localStorage.getItem("myAppId"));
  }, [])

  return (
    <div style={{ display: ConfigStore.activeButton === 2 ? "flex" : "none" }} className='myQRcode-page'>
      <div className="myQRcode">
        {currentURL && <QRCode value={currentURL} size={300} />}
      </div>
    </div>
  );
});

export default QRCodeGenerator;