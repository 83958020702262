import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react";
import { useStores } from "../Stores/MainStore";
import { Box, Button, Typography, Modal, TextField } from '@mui/material';
import { language } from "../lang";
import InputMask from 'react-input-mask';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const UpdateMeModal = observer(() => {

    const { ConfigStore, RequestStore } = useStores();

    const [lng, setLng] = useState(ConfigStore.lang);
    const [uname, setUname] = useState("");
    const [usurname, setUsurname] = useState("");
    const [uphone, setUphone] = useState();
    const today = dayjs();
    const [ubirthday, setUbirthday] = useState(today);

    const {
        register,
        handleSubmit,
        resetField,
        formState: { errors },
    } = useForm({
        mode: "onChange",
    });

    const handleClose = () => {
        ConfigStore.setIsUpdateMeShow(false);
    };

    const onSubmit = (data) => {
        new Promise((resolve, reject) => {
            resolve();
        })
        .then(() => {
            return RequestStore.doPut(ConfigStore._url + '/me/' + localStorage.getItem('myAppId'), {
                name: (data.uname).replace(/ /g,"_"),
                surname: (data.usurname).replace(/ /g,"_"),
                birthday: ubirthday.$d,
                phone: data.uphone
            });
        })
        .then((res) => {
            if (res.acknowledged === true && res.modifiedCount === 1) {
                resetField("uname");
                resetField("usurname");
                resetField("ubirthday");
                resetField("uphone");
                ConfigStore.setIsUpdateMeShow(false);
                ConfigStore.setSeverity("success");
                ConfigStore.setTextAlert(lng === "de" ? "Erfolg!" : "Success!");
                ConfigStore.setIsSnackShow(true);
                RequestStore.doGet(ConfigStore._url + "/me/" + localStorage.getItem("myAppId"))
                .then((res) => {
                    ConfigStore.setMyData(res);
                })
            } else {
                ConfigStore.setIsUpdateMeShow(false);
                ConfigStore.setSeverity("error");
                ConfigStore.setTextAlert(lng === "de" ? "Ein Fehler ist aufgetreten :(" : "Some error occured :(");
                ConfigStore.setIsSnackShow(true);
            }
        })
    };

    useEffect(() => {
        setLng(ConfigStore.lang);
    }, [ConfigStore.lang]);

    return(
        <Modal
            open={ConfigStore.isUpdateMeShow}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="modalFeedback" >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {lng === "de" ? language.updateMe.de : language.updateMe.en}
                </Typography>
                <Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="feedback__form">
                            <TextField
                                label={lng === "de" ? language.name.de : language.name.en}
                                variant="standard" 
                                type="text"
                                fullWidth
                                {...register("uname", {
                                    required: `${lng === "de" ? language.required.de : language.required.en}`,
                                    minLength: {
                                        value: 2,
                                        message: `${lng === "de" ? language.min2symb.de : language.min2symb.en}`
                                    },
                                    value: uname,
                                    onChange: (e) => {
                                        setUname(e.target.value)
                                    }
                                })}
                            />
                            <p className="errorMessage">{errors.uname && errors.uname.message}</p>
                            <TextField
                                label={lng === "de" ? language.surname.de : language.surname.en}
                                variant="standard"
                                type="text"
                                fullWidth
                                {...register("usurname", {
                                    required: `${lng === "de" ? language.required.de : language.required.en}`,
                                    minLength: {
                                        value: 2,
                                        message: `${lng === "de" ? language.min2symb.de : language.min2symb.en}`
                                    },
                                    value: usurname,
                                    onChange: (e) => {
                                        setUsurname(e.target.value)
                                    }
                                })}
                            />
                            <p className="errorMessage">{errors.usurname && errors.usurname.message}</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        views={['day','month','year']}
                                        label={lng === "de" ? language.birthday.de : language.birthday.en}
                                        format="DD/MM/YYYY"
                                        value={ubirthday}
                                        onChange={(newValue) => {
                                            setUbirthday(newValue)
                                        }}
                                        slotProps={{
                                            textField: {
                                                size: 'small'
                                            },
                                            actionBar: {
                                                actions: ['clear', 'accept']
                                            }
                                        }}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            <TextField
                                label={lng === "de" ? language.phone.de : language.phone.en}
                                variant="standard"
                                fullWidth
                                InputProps={{
                                    inputComponent: PhoneNumberInput,
                                }}
                                {...register("uphone", {
                                    required: `${lng === "de" ? language.required.de : language.required.en}`,
                                    value: uphone,
                                    onChange: (e) => {
                                        setUphone(e.target.value)
                                    }
                                })}
                            />
                            <p className="errorMessage">{errors.uphone && errors.uphone.message}</p>
                        </div>
                        <Button type="submit" variant="outlined">
                            {lng === "de" ? language.send.de : language.send.en}
                        </Button>
                    </form>
                </Box>
            </Box>
        </Modal>
    );
});

const PhoneNumberInput = React.forwardRef((props, ref) => {
    return (
        <InputMask
            {...props}
            mask="+49 9999 999 99 99"
            maskChar=" "
            formatChars={{
            '9': '[0-9]',
            }}
        />
    );
});

export default UpdateMeModal;