import * as React from "react";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../../Stores/MainStore";
import ErrorModal from "../../../Partial/ErrorModal";
import { typeListde, typeListen } from "../../../TypeList";
import { language } from "../../../lang";

const SUService = observer(() => {

    return(
        <>
        
        </>
    );

});

export default SUService