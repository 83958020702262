import React, { useState, useEffect } from "react";
import "./visitor.scss";
import { observer } from "mobx-react";
import { useStores } from "../../Stores/MainStore";
import { language } from "../../lang";
import { Button, TextField } from '@mui/material';
import UpdateMeModal from "../../Partial/UpdateMeModal";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const Me = observer(() => {

    const { ConfigStore } = useStores();

    const [lng, setLng] = useState(ConfigStore.lang);

    useEffect(() => {
        setLng(ConfigStore.lang);
    }, [ConfigStore.lang]);

    const handleOpenUpdMe = () => {
        ConfigStore.setIsUpdateMeShow(true);
    };

    return(
        <div className="about__info" style={{ display: ConfigStore.activeButton === 1 ? "flex" : "none" }} >
            <div className="info__me">
                {ConfigStore.myData.username ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label={(lng === "de" ? language.username.de : language.username.en) + ":"}
                        value={(ConfigStore.myData.username).replace(/_/g," ")}
                        fullWidth
                    />
                ) : (
                    null
                )}
                {ConfigStore.myData.name ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label={(lng === "de" ? language.name.de : language.name.en) + ":"}
                        value={(ConfigStore.myData.name).replace(/_/g," ")}
                        fullWidth
                    />
                ) : (
                    null
                )}
                {ConfigStore.myData.surname ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label={(lng === "de" ? language.surname.de : language.surname.en) + ":"}
                        value={(ConfigStore.myData.surname).replace(/_/g," ")}
                        fullWidth
                    />
                ) : (
                    null
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        {
                            ConfigStore.myData.birthday ? 
                            (<DatePicker
                                views={['day','month','year']}
                                label={(lng === "de" ? language.birthday.de : language.birthday.en) + ":"}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        variant: 'filled'
                                    }
                                }}
                                value={dayjs(ConfigStore.myData.birthday)}
                                readOnly
                            />)
                            :
                            null
                        }
                    </DemoContainer>
                </LocalizationProvider>
                {ConfigStore.myData.phone ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label={(lng === "de" ? language.phone.de : language.phone.en) + ":"}
                        value={ConfigStore.myData.phone}
                    />
                ) : (
                    null
                )}
                {ConfigStore.myData.bonus ? (
                    <TextField
                        variant="filled"
                        InputProps={{
                            readOnly: true,
                        }}
                        label={"Bonus:"}
                        value={ConfigStore.myData.bonus}
                    />
                ) : (
                    null
                )}
                <Button
                    onClick={handleOpenUpdMe}
                    variant="contained"
                >
                    {lng === "de" ? language.updateMe.de : language.updateMe.en}
                </Button>
                <UpdateMeModal />
            </div>
        </div>
    );
});

export default Me;