export const language = {
    "signin" : {
        "de" : "anmeldung",
        "en" : "signin"
    },
    "signup" : {
        "de" : "registrieren",
        "en" : "signup"
    },
    "email" : {
        "de" : "Ungültige E-Mail-Adresse",
        "en" : "Invalid email address"
    },
    "feedback" : {
        "de" : "rückmeldung",
        "en" : "feedback"
    },
    "business_type" : {
        "de" : "Wählen Sie eine Art von Geschäft",
        "en" : "Choose a type of business"
    },
    "business_name_valid" : {
        "de" : "Nur Buchstaben des lateinischen Alphabets, arabische Zahlen, Bindestriche, Unterstriche und Leerzeichen",
        "en" : "Only latins alphabet letters, arabic numbers, hyphens, underscores and white space"
    },
    "phone_num_valid" : {
        "de" : "Beginne mit '+' und Ländercode, dann volle Nummer ohne Bindestriche und Leerzeichen",
        "en" : "Start from '+' and country code, then full number without hyphens and white spaces"
    },
    "password_valid" : {
        "de" : "Passwort sollte enthalten: 8 Zeichen, darunter 1 Großbuchstabe, 1 Kleinbuchstabe und 1 Zahl oder Sonderzeichen",
        "en" : "Password should contain: 8 characters including 1 uppercase letter, 1 lowercase letter, and 1 number or special character"
    },
    "logout" : {
        "de" : "ausloggen",
        "en" : "logout"
    },
    "tagline" : {
        "de" : "Motivation darüber hinaus, Boni innerhalb!",
        "en" : "Motivation beyond, bonuses within!"
    },
    "about_s__cust" : {
        "de" : {
            "for" : "Für Kunden:",
            "about" : "Wir stellen eine Treue-App vor, die Ihnen dabei hilft, mehr aus Ihren Einkäufen herauszuholen - verdienen Sie Boni und erhalten Sie exklusive Angebote von Unternehmen innerhalb unserer App."
        },
        "en" : {
            "for" : "For customers:",
            "about" : "We introduce a loyalty app that helps you get more out of your purchases - earn bonuses and receive exclusive offers from companies within our app."
        }
    },
    "about_s__own" : {
        "de" : {
            "for" : "Für Unternehmen:",
            "about" : "Unsere Treue-App bringt Unternehmen und Mitarbeiter zusammen - Sie können sich registrieren, Angebote hinzufügen und entfernen und verfolgen, wie viele Boni Ihre Besucher verdient haben."
        },
        "en" : {
            "for" : "For business:",
            "about" : "Our loyalty app brings together companies and employees - you can register, add and remove offers, and track how many bonuses your visitors have earned."
        }
    },
    "learnMore" : {
        "de" : "mehr erfahren",
        "en" : "lern more"
    },
    "getStarted" : {
        "de" : "loslegen",
        "en" : "get started"
    },
    "aboutApp" : {
        "de" : "Über die App",
        "en" : "About App"
    },
    "about_f" : {
        "de" : {
            "hello" : "Liebe Besucher,",
            "ask" : "kennen Sie schon die Applikation 'Kroken'? Wenn nicht, lassen Sie sich einführen. Wir haben dieses Bonusprogramm entwickelt, damit unsere Kunden davon profitieren können. Die erhaltenen Boni können lediglich dort verwendet werden, wo der QR-Code gescannt wurde. Inhaber/innen können ihr Geschäft fördern, indem sie einige Kundenbindungsprogramme einführen. Um Ihr eigenes Unternehmen zu fördern, stehen folgende Angebote zur Verfügung:",
            "users" : {
                "usMain" : "Das Bonusprogramm hat zwei Login-Typen:",
                "usVis" : "- Gast",
                "usOwn" : "- Inhaber/in eines Unternehmens"
            },
            "forVis" : {
                "for" : "Für Kunden:",
                "about" : "Die Registrierung ist einfach. Sie brauchen nur einige Pflichtfelder auszufüllen, um die verschiedenen Angebote zu sehen. Zudem wird die erforderliche Anzahl von Boni gezeigt, sowie Geschenke für die Teilnahme am Bonusprogramm und mögliche Belohnungsarten. Sind die Voraussetzungen für die jeweiligen Bereiche erfüllt, um den Bonus zu erhalten - fragen Sie nach dem QR-Code. Scannen Sie im und schicken ihm ab. Sofort wird der Bonus Ihnen gutgeschrieben. Um den Bonus verrechnen zu können, sollen Sie die Mitarbeiter nach einem weiteren QR-Code fragen. Scannen Sie ihn und geben die Anzahl der abzuhebenden Boni ein. Danach die Bestätigungstaste drücken und Ihre Belohnung genießen."
            },
            "forOwn" : {
                "for" : "Für Unternehmen:",
                "about" : "Wenn Sie Inhaber/in eines Unternehmens sind, registrieren Sie sich einfach als Inhaber. Bei der Registrierung geben Sie den Namen, die Art Ihres Unternehmens und andere Pflichtfelder ein. Nach der Eintragung wird Ihre persönliche Seite geöffnet, wo ein Angebot hinzufügt werden kann.",
                "qr_codes" : "Dann erhalten Sie 3 QR-Codes:",
                "one" : "1. Sie brauchen einen öffentlichen QR-Code, damit Ihre Kunden sich registrieren können.",
                "two" : "2. Dieser sollte dem Kunden nach Zahlungen gegeben werden, damit er den Bonus auf seinen Konten erhalten kann.",
                "three" : "3. Und der letzte QR-Code ist für den Auszahlungsbonus erforderlich."
            }
        },
        "en" : {
            "hello" : "Dear visitor,",
            "ask" : "Have you heard about the web application Kroken? If not, let us introduce it to you. We developed this application to help visitors discover new features from lovely locations such as restaurants, hairdressing salons, and more. In addition to this, visitors can earn bonuses and spend them in these places. Business owners can also promote their business by introducing customer loyalty programs.",
            "users" : {
                "usMain" : "The application has two login types:",
                "usVis" : "- Visitor",
                "usOwn" : "- Owner of a business"
            },
            "forVis" : {
                "for" : "For visitor:",
                "about" : "simply complete the registration form, and you will be directed to your personal page where you can find all the existing offers, the number of bonuses required to get a gift, and the kind of gift you can get. Once you meet the required condition to get a bonus, ask for a QR code, scan it, and press the 'Submit' button. You will receive your bonus in just a few moments. Once you have earned enough bonuses, you can spend them by asking for the required QR code, scanning it, entering the number of bonuses you want to withdraw, and pressing the confirm button."
            },
            "forOwn" : {
                "for" : "For business:",
                "about" : "simply register by clicking the 'Sign Up' button above. Enter the name of your business, the type of business you have, and other required fields. Once you complete the registration, you can easily add offers by filling in the required fields.",
                "qr_codes" : "Then, you will receive three QR codes:", 
                "one" : "1. Place the first QR code in free and accessible spaces for visitors to register.",
                "two" : "2. Give the second QR code to your customers only after payment, so that they can earn bonuses on their account.",
                "three" : "3. Use the third QR code for bonus withdrawals."
            }
        }
    },
    "gotIt" : {
        "de" : "verstanden",
        "en" : "got it"
    },
    "fillUpForm" : {
        "de" : "Füllen Sie das Formular aus",
        "en" : "Fill up the form"
    },
    "connect" : {
        "de" : "Ihre telefonnummer oder E-Mail-Adresse",
        "en" : "Phone number or email"
    },
    "phone" : {
        "de" : "Telefonnummer",
        "en" : "Phone number"
    },
    "updateMe" : {
        "de" : "Aktualisiere",
        "en" : "Update my info"
    },
    "topic" : {
        "de" : "Thema",
        "en" : "Topic"
    },
    "message" : {
        "de" : "Nachricht",
        "en" : "Message"
    },
    "send" : {
        "de" : "absenden",
        "en" : "send"
    },
    "required" : {
        "de" : "Feld ist erforderlich",
        "en" : "Field is required"
    },
    "min2symb" : {
        "de" : "Mindestens 2 Zeichen",
        "en" : "Minimum 2 symbols"
    },
    "min8symb" : {
        "de" : "Mindestens 8 Zeichen",
        "en" : "Minimum 8 symbols"
    },
    "logname" : {
        "de" : "Benutzername / E-Mail",
        "en" : "Username / Email"
    },
    "username" : {
        "de" : "Benutzername",
        "en" : "Username"
    },
    "name" : {
        "de" : "Vorname",
        "en" : "Name"
    },
    "surname" : {
        "de" : "Nachname",
        "en" : "Surname"
    },
    "birthday" : {
        "de" : "Gebürtsdatum",
        "en" : "Birthday"
    },
    "password" : {
        "de" : "Passwort",
        "en" : "Password"
    },
    "businessName" : {
        "de" : "Unternehmen",
        "en" : "Business name"
    },
    "owner" : {
        "de" : "Inhaber/in",
        "en" : "Owner"
    },
    "visitor" : {
        "de" : "Gast",
        "en" : "Visitor"
    },
    "cond" : {
        "de" : "Voraussetzung",
        "en" : "Condition"
    },
    "gift" : {
        "de" : "Geschenk",
        "en" : "Gift"
    },
    "required_bon" : {
        "de" : "Erforderlicher Bonus",
        "en" : "Required bonus"
    },
    "prise" : {
        "de" : "Preis",
        "en" : "Prise"
    },
    "conf" : {
        "de" : "Bestätigen",
        "en" : "Confirm"
    },
    "sendNewOffer" : {
        "de" : "Neues Angebot senden",
        "en" : "Send new offer"
    },
    "allVis" : {
        "de" : "Hier sind alle Ihre Besucher",
        "en" : "Here is all your visitors"
    },
    "anyUser" : {
        "de" : "Es tut uns leid, Sie haben immer noch keine Besucher registriert",
        "en" : "Sorry you still have any visitor registered"
    },
    "allOffers" : {
        "de" : "Hier sind alle Ihre Angebote",
        "en" : "Here is all your offers"
    },
    "delete" : {
        "de" : "löschen",
        "en" : "delete"
    },
    "anyOffers" : {
        "de" : "Es tut uns leid, es gibt immer noch keine Angebote",
        "en" : "Sorry, still any offers"
    },
    "historyOffer" : {
        "de" : "Hier sind Ihre gelöschten Angebote",
        "en" : "Here is your deleted offers"
    },
    "return" : {
        "de" : "Zurückgeben",
        "en" : "return"
    },
    "anyHistory" : {
        "de" : "Es tut uns leid, es gibt keine gelöschten Angebote",
        "en" : "Sorry, any deleted offers"
    }
}